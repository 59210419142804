<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Фид'" :title="'Список Квартир'">
      <div class="list__buttons">
        <MainButton @click.native="handleBack()">К списку фидов</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div v-if="feedData" class="feed">
        <div class="feed__title">Feed Data</div>
        <div class="feed__list">
          <div class="feed__list-item"><span>Link:</span> {{ feedData.link }}</div>
        </div>
        <div class="feed__list">
          <div class="feed__list-item"><span>Status:</span> {{ feedData.status.name }}</div>
        </div>
        <div class="feed__list">
          <div class="feed__list-item">
            <span>Update:</span> {{ transformDate(feedData.importedAt) }}
          </div>
        </div>
        <div class="feed__list">
          <div class="feed__list-item"><span>Flat count:</span> {{ flats.total }}</div>
        </div>
      </div>
      <div class="list">
        <div v-if="flatsList.length">
          <CustomTable
            v-if="flatsList.length"
            :data="flatsList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handleFilter="handleFilter"
            :handle-logs="handleOpenLogsModal"
            :direction="directionSort"
            :order="orderSort"
            :name="'flats'"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="flats.lastPage"
              :page="page"
              :urlList="'FLAT_LIST'"
              :totalItem="flats.total"
              :feedId="feedID"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Flats found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Flats - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="logsModal" :on-close="handleCloseLogsModal" :modal-center="true">
      <div class="logs-modal">
        <div class="logs-modal__title">Logs List</div>
        <div v-if="logsModalArray.length > 0" class="logs-modal__list">
          <div v-for="(log, index) in logsModalArray" :key="index" class="logs-modal__list-item">
            <div class="logs-modal__list-header">
              <div>{{ formatDate(log.elasticCreatedAt) }}</div>
              <div>{{ log.logEvent }}</div>
              <div>{{ log.logType }}</div>
            </div>
            <div><b>Message:</b> {{ log.message }}</div>
            <div class="logs-modal__error" v-if="log.errorMessage">
              <b>Error Message:</b> {{ log.errorMessage }}
            </div>
          </div>
        </div>
        <div class="logs-modal__no-logs" v-else>No Logs</div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Flats {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import feedApi from "~/api/feed";
import moment from "moment";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";

export default {
  name: "FlatsList",
  metaInfo: {
    title: "Flats List",
  },
  data() {
    return {
      logsModal: false,
      logsModalArray: [],
      feedData: null,
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "id",
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "url",
            to: "Url",
          },
          {
            from: "status_flat",
            to: "Status",
          },
          {
            from: "video_url",
            to: "Video",
          },
          {
            from: "video_credit",
            to: "Credit",
          },
          {
            from: "video_count_generate",
            to: "Number of generations",
          },
          {
            from: "external_id",
            to: "Flat ID",
          },
          {
            from: "created_at",
            to: "Created At",
          },
          {
            from: "updated_at",
            to: "Updated At",
          },
          {
            from: "video_date",
            to: "Video Date",
          },
        ],
        customOrder: [
          "id",
          "external_id",
          "created_at",
          "updated_at",
          "url",
          "status_flat",
          "video_url",
          "video_credit",
          "video_count_generate",
          "video_date",
          "null",
        ],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
  },
  mounted() {
    this.getList();
    this.getItem();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("feed", ["error", "loadingProcess", "flats", "flatsList"]),
    page() {
      return +this.$route.params.page;
    },
    feedID() {
      return this.$route.params.feedId + "";
    },
    getMaxPage() {
      return Math.ceil(this.flats.pagination.allCount / 20);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getList() {
      let url = `?page=${this.page}`;
      if (this.feedID) {
        url += `&feed_id=${this.feedID}`;
      }
      if (this.orderSort.length) {
        url =
          url +
          `&relations[0]=status&relations[1]=images&relations[2]=video&relations[3]=logs&sortField=${
            this.orderSort
          }&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      this.$store.dispatch("feed/getFlatsList", url);
    },
    getItem() {
      const url = `/${this.feedID}?relations[0]=status`;
      try {
        feedApi.getItemFeed(url).then((res) => {
          const data = res.data;
          this.feedData = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({
        path: ROUTE.FEED_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleDetail(row) {
      this.$router.push({
        path: ROUTE.FLAT_DETAIL.replace(":id", `${row.id}`),
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
    handleBack() {
      this.$router.push({
        path: ROUTE.FEED_LIST.replace(":page", "1"),
      });
    },
    transformDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    handleCloseLogsModal() {
      this.logsModal = false;
      this.logsModalArray = [];
    },
    handleOpenLogsModal(row) {
      this.logsModal = true;
      this.logsModalArray = this.flatsList.find((item) => item.id === row.id).logs;
      console.log(this.logsModalArray);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr 1fr 2fr 1fr 2fr 0.5fr 2fr 1fr 0.3fr !important;
}

.feed {
  &__list {
    padding-bottom: 20px;
  }

  &__title {
    font-size: 24px;
    padding-bottom: 20px;
    color: grey;
  }
  &__list-item {
    font-size: 18px;

    span {
      font-weight: 700;
      color: grey;
      padding-right: 40px;
    }
  }
}

.logs-modal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &__no-logs {
    font-size: 30px;
    font-weight: 700;
    color: grey;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
  }

  &__list {
    width: 100%;
  }

  &__list-item {
    width: 100%;
    font-size: 18px;
    padding: 15px 15px 15px;
    background: rgb(238, 238, 238);
    border-radius: 10px;
    margin-bottom: 10px;

    div {
      padding-right: 30px;
    }
  }

  &__list-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__error {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* Ограничение до 4 строк */
    overflow: hidden;
  }
}
</style>
